<template>
  <div class="admin-detail">
    <div class="admin-navbar justify-between">
      <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
      <div class="detail-buttons">
        <button class="detail-button"
                @click="$router.push(`/admin/deadlines/${$route.params.id}/edit`)">
          {{ $lang.app.edit }}</button>
      </div>
    </div>
    <div v-if="deadline" class="admin-info">
      <h4 class="info-title">{{ $lang.app.deadline_info }}</h4>
      <div class="info-fields">
        <div class="info-field">
          <p class="field-label">{{ $lang.app.title }}:</p>
          <p class="field-value">{{ deadline.name }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.registration_by_residence_start }}:</p>
          <p class="field-value">{{ deadline.registrationByResidenceStart }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.registration_by_residence_end }}:</p>
          <p class="field-value">{{ deadline.registrationByResidenceEnd }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.registration_outside_residence_start }}:</p>
          <p class="field-value">{{ deadline.registrationOutsideResidenceStart }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.registration_outside_residence_end }}:</p>
          <p class="field-value">{{ deadline.registrationOutsideResidenceEnd }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.document_submission_start }}:</p>
          <p class="field-value">{{ deadline.documentSubmissionStart }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.document_submission_end }}:</p>
          <p class="field-value">{{ deadline.documentSubmissionEnd }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.first_grade_enrollment_start }}:</p>
          <p class="field-value">{{ deadline.firstGradeEnrolmentStart }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.first_grade_enrollment_end }}:</p>
          <p class="field-value">{{ deadline.firstGradeEnrolmentEnd }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.registration_deadline }}:</p>
          <p class="field-value">{{ deadline.registrationDeadline }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.gorono_registration_start }}:</p>
          <p class="field-value">{{ deadline.goronoRegistrationStart }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.gorono_registration_end }}:</p>
          <p class="field-value">{{ deadline.goronoRegistrationEnd }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.archived }}:</p>
          <p class="field-value">{{ deadline.archived ? $lang.app.yes : $lang.app.no }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      deadline: null,
    }
  },
  methods: {
    fetchResource() {
      this.$axios.get(`/academic-year/${this.$route.params.id}`).then(({data}) => {
        this.deadline = data
      })
    }
  },
  mounted() {
    this.fetchResource()
  }
}
</script>
<style>
@import '../../../assets/styles/admin/detail.css';
@import '../../../assets/styles/admin/info.css';
@import '../../../assets/styles/admin/navbar.css';
</style>